
















import Vue from 'vue'

export default Vue.extend({
  name: 'StyleGuide',
  props: {
    color_primary: {
      type: String,
    },
    color_secondary: {
      type: String,
    },
    color_text: {
      type: String,
    },
    font_primary: {
      type: String,
    },
    font_secondary: {
      type: String,
    },
    button_radius: {
      type: String,
    },
  },
})
